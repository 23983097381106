<template>
  <div class="px-3">
    <div>
      <div class="px-2">
        <!-- Text Image Button -->
        <div class="pb-3" style="text-align: center">
          <div class="btn-group" role="group" aria-label="First group">
            <button
              type="button"
              :class="
                getCurrentSelectedOptionType === 'text'
                  ? 'text-img-btn-selected'
                  : ''
              "
              class="btn button-group px-3"
              @click="setOptionType('text')"
            >
              <svg
                v-if="getCurrentSelectedOptionType === 'text'"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 4H4V20H20V4ZM4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4Z"
                  fill="#ffffff"
                />
                <path
                  d="M10.6429 17L6 12.2015L7.30929 10.8484L10.6429 14.2841L17.6907 7L19 8.36276L10.6429 17Z"
                  fill="#ffffff"
                />
              </svg>
              <svg
                v-else
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M20 4H4V20H20V4ZM4 2C2.89543 2 2 2.89543 2 4V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V4C22 2.89543 21.1046 2 20 2H4Z"
                  fill="#6D6B6D"
                />
                <path
                  d="M10.6429 17L6 12.2015L7.30929 10.8484L10.6429 14.2841L17.6907 7L19 8.36276L10.6429 17Z"
                  fill="#6D6B6D"
                />
              </svg>
            </button>
            <button
              type="button"
              :class="
                getCurrentSelectedOptionType === 'image'
                  ? 'text-img-btn-selected'
                  : ''
              "
              class="btn button-group px-3"
              @click="setOptionType('image')"
            >
              <svg
                v-if="getCurrentSelectedOptionType === 'image'"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7778 4.22222V19.7778H4.22222V4.22222H19.7778ZM19.7778 2H4.22222C3 2 2 3 2 4.22222V19.7778C2 21 3 22 4.22222 22H19.7778C21 22 22 21 22 19.7778V4.22222C22 3 21 2 19.7778 2ZM14.3778 11.8444L11.0444 16.1444L8.66667 13.2667L5.33333 17.5556H18.6667L14.3778 11.8444Z"
                  fill="#ffffff"
                />
              </svg>
              <svg
                v-else
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.7778 4.22222V19.7778H4.22222V4.22222H19.7778ZM19.7778 2H4.22222C3 2 2 3 2 4.22222V19.7778C2 21 3 22 4.22222 22H19.7778C21 22 22 21 22 19.7778V4.22222C22 3 21 2 19.7778 2ZM14.3778 11.8444L11.0444 16.1444L8.66667 13.2667L5.33333 17.5556H18.6667L14.3778 11.8444Z"
                  fill="#6D6B6D"
                />
              </svg>
            </button>
            <button
              type="button"
              :class="
                getCurrentSelectedOptionType === 'dropdown'
                  ? 'text-img-btn-selected'
                  : ''
              "
              class="btn button-group px-3"
              @click="setOptionType('dropdown')"
            >
              <svg
                v-if="getCurrentSelectedOptionType === 'dropdown'"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V2C20 0.9 19.1 0 18 0ZM18 18H2V2H18V18Z"
                  fill="#ffffff"
                />
                <path
                  d="M6.72426 9C6.45699 9 6.32314 9.32314 6.51213 9.51213L9.78787 12.7879C9.90503 12.905 10.095 12.905 10.2121 12.7879L13.4879 9.51213C13.6769 9.32314 13.543 9 13.2757 9H6.72426Z"
                  fill="#ffffff"
                />
              </svg>
              <svg
                v-else
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0H2C0.9 0 0 0.9 0 2V18C0 19.1 0.9 20 2 20H18C19.1 20 20 19.1 20 18V2C20 0.9 19.1 0 18 0ZM18 18H2V2H18V18Z"
                  fill="#6D6B6D"
                />
                <path
                  d="M6.72426 9C6.45699 9 6.32314 9.32314 6.51213 9.51213L9.78787 12.7879C9.90503 12.905 10.095 12.905 10.2121 12.7879L13.4879 9.51213C13.6769 9.32314 13.543 9 13.2757 9H6.72426Z"
                  fill="#6D6B6D"
                />
              </svg>
            </button>
            <button
              type="button"
              :class="
                getCurrentSelectedOptionType === 'tab'
                  ? 'text-img-btn-selected'
                  : ''
              "
              class="btn button-group px-3"
              @click="setOptionType('tab')"
            >
              <svg
                v-if="getCurrentSelectedOptionType === 'tab'"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13.2H20V10.8H4V13.2ZM4 18H20V15.6H4V18ZM4 6V8.4H20V6H4ZM4 13.2H20V10.8H4V13.2ZM4 18H20V15.6H4V18ZM4 6V8.4H20V6H4Z"
                  fill="#ffffff"
                />
              </svg>
              <svg
                v-else
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 13.2H20V10.8H4V13.2ZM4 18H20V15.6H4V18ZM4 6V8.4H20V6H4ZM4 13.2H20V10.8H4V13.2ZM4 18H20V15.6H4V18ZM4 6V8.4H20V6H4Z"
                  fill="#6D6B6D"
                />
              </svg>
            </button>
          </div>
        </div>

        <!-- Answer options -->
        <div>
          <div class="px-2">
            <div class="form-group d-flex mb-2 align-items-center row">
              <div class="col-12">
                <span class="editorStyleTitle"
                  ><strong> Answer Options:</strong></span
                >
              </div>
            </div>

            <!-- Single Anwer radio options input -->
            <div
              v-show="
               getCurrentSelectedOptionType === 'text' ||
               getCurrentSelectedOptionType == 'tab' ||
               getCurrentSelectedOptionType == 'dropdown'
              "
              class="OptionsContainer"
            >
              <draggable
                v-model="block.options"
                @start="RadioOptionDragging = true"
                @end="RadioOptionDragging = false"
                style="cursor: move"
              >
                <div
                  v-for="(radioOption, index2) in block.options"
                  :key="index2"
                >
                  <div class="form-group d-flex mb-2 align-items-center row">
                    <div class="col-1 px-0">
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.25 11.25V12.75H12.75V11.25H5.25ZM5.25 7.5V9H12.75V7.5H5.25ZM5.25 3.75V5.25H12.75V3.75H5.25Z"
                          fill="#B3AFB6"
                        />
                      </svg>
                    </div>
                    <div class="col-9 px-0">
                      <div>
                        <b-form-textarea
                          class="form-control px-3 w-100 input-option"
                          style="
                            border: none;
                            outline: none;
                            border-radius: 2px;
                            outline: #ffa201 solid 1px;
                          "
                          size="sm"
                          v-model="radioOption.value"
                          placeholder="answer option"
                          min-rows="1"
                          max-rows="5"
                        ></b-form-textarea>
                      </div>
                    </div>
                    <div class="col-2 px-0" v-if="block.options.length > 2">
                      <button
                        class="btn p-0"
                        @click="
                          deleteSingleAnswerOption(index2, radioOption.id)
                        "
                      >
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                            fill="transparent"
                            fill-opacity="0.5"
                          />
                          <path
                            d="M10.2856 23.2593C10.2856 24.4 11.1428 25.3333 12.1904 25.3333H19.8094C20.8571 25.3333 21.7142 24.4 21.7142 23.2593V10.8148H10.2856V23.2593ZM12.1904 12.8889H19.8094V23.2593H12.1904V12.8889ZM19.3333 7.70369L18.3809 6.66666H13.619L12.6666 7.70369H9.33325V9.77777H22.6666V7.70369H19.3333Z"
                            fill="#b3afb6"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
            <!-- AnsOption With Image -->
            <div v-show="getCurrentSelectedOptionType === 'image'">
              <!-- Image width Height Inputs -->
              <div
                class="form-group d-flex mb-2 align-items-center row page-size"
              >
                <div
                  class="d-flex align-items-center col-4"
                  v-if="isDesktopView"
                >
                  <label
                    for="imageDesktopWidth"
                    class="mb-0"
                    v-b-tooltip.hover
                    title="Image Width on desktop"
                    >W</label
                  >
                  <input
                    type="number"
                    v-model="block.style.imageWidth"
                    class="form-control-sm d-inline input-border-style ml-2"
                    style="max-width: 55px"
                    id="imageDesktopWidth"
                  />
                </div>
                <div class="d-flex align-items-center col-4" v-else>
                  <label
                    for="imageMobileWidth"
                    class="mb-0"
                    v-b-tooltip.hover
                    title="Image Width on Mobile"
                    >W</label
                  >
                  <input
                    type="number"
                    v-model="block.style.imageMobileWidth"
                    class="form-control-sm d-inline input-border-style ml-2"
                    style="max-width: 55px"
                    id="imageMobileWidth"
                  />
                </div>

                <div
                  class="d-flex align-items-center col-4"
                  v-if="isDesktopView"
                >
                  <label
                    for="imageHeightDesktop"
                    class="mb-0"
                    v-b-tooltip.hover
                    title="Image Height on desktop"
                    >H</label
                  >
                  <input
                    type="number"
                    v-model="block.style.imageHeight"
                    class="form-control-sm d-inline input-border-style ml-2"
                    style="max-width: 55px"
                    id="imageHeightDesktop"
                  />
                </div>
                <div class="d-flex align-items-center col-4" v-else>
                  <label
                    for="imageMobileHeight"
                    class="mb-0"
                    v-b-tooltip.hover
                    title="Image Height on Mobile"
                    >H</label
                  >
                  <input
                    type="number"
                    v-model="block.style.imageMobileHeight"
                    class="form-control-sm d-inline input-border-style ml-2"
                    style="max-width: 55px"
                    id="imageMobileHeight"
                  />
                </div>
                <div class="d-flex align-items-center col-4">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15.8333 15.8333H17.5V17.5H15.8333V15.8333ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM2.5 10.8333H4.16667V9.16667H2.5V10.8333ZM2.5 14.1667H4.16667V12.5H2.5V14.1667ZM2.5 7.5H4.16667V5.83333H2.5V7.5ZM2.5 4.16667H4.16667V2.5H2.5V4.16667ZM5.83333 4.16667H7.5V2.5H5.83333V4.16667ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM5.83333 17.5H7.5V15.8333H5.83333V17.5ZM2.5 17.5H4.16667V15.8333H2.5V17.5ZM17.5 6.66667C17.5 4.36667 15.6333 2.5 13.3333 2.5H9.16667V4.16667H13.3333C14.7083 4.16667 15.8333 5.29167 15.8333 6.66667V10.8333H17.5V6.66667Z"
                      fill="#B3AFB6"
                    />
                  </svg>

                  <input
                    type="number"
                    v-model="block.style.borderRadius"
                    class="input-border-style ml-2"
                    style="max-width: 55px"
                  />
                </div>
              </div>

              <div class="OptionsContainer">
                <draggable
                  v-model="block.options"
                  @start="RadioOptionImageDragging = true"
                  @end="RadioOptionImageDragging = false"
                  style="cursor: move"
                >
                  <div
                    v-for="(imageRadioOption, index2) in block.options"
                    :key="index2"
                  >
                    <div class="form-group mb-2 row d-flex align-items-center">
                      <div class="col-1 px-0">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.25 11.25V12.75H12.75V11.25H5.25ZM5.25 7.5V9H12.75V7.5H5.25ZM5.25 3.75V5.25H12.75V3.75H5.25Z"
                            fill="#B3AFB6"
                          />
                        </svg>
                      </div>

                      <div class="col-2 px-1">
                        <div
                          v-if="!imageRadioOption.image"
                          style="
                            width: 100%;
                            height: 40px;
                            background: #cccace;
                            cursor: pointer;
                          "
                          class="d-flex align-items-center justify-content-center"
                          @click="openModal(index2)"
                        >
                          <img
                            src="/images/image_placeholder.png"
                            alt=""
                            style="height: 100%; width: 100%; object-fit: cover"
                          />
                        </div>
                        <div
                          v-else
                          style="width: 100%; height: 40px; cursor: pointer"
                          class=""
                          @click="openModal(index2, imageRadioOption.image)"
                        >
                          <img
                            :src="ImageSrc(imageRadioOption.image)"
                            alt=""
                            style="height: 100%; width: 100%"
                          />
                        </div>
                      </div>
                      <div class="col-7 px-0">
                        <div>
                          <b-form-textarea
                            class="form-control px-3 w-100 input-option"
                            style="
                              border: none;
                              border-radius: 2px;
                              outline: #ffa201 solid 1px;
                            "
                            size="sm"
                            v-model="imageRadioOption.value"
                            placeholder="answer option"
                            min-rows="1"
                            max-rows="5"
                          ></b-form-textarea>
                        </div>
                      </div>
                      <div class="col-2 px-0" v-if="block.options.length > 2">
                        <button
                          class="btn p-0"
                          @click="
                            deleteSingleAnswerOption(
                              index2,
                              imageRadioOption.id
                            )
                          "
                        >
                          <svg
                            width="28"
                            height="28"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 4C0 1.79086 1.79086 0 4 0H28C30.2091 0 32 1.79086 32 4V28C32 30.2091 30.2091 32 28 32H4C1.79086 32 0 30.2091 0 28V4Z"
                              fill="transparent"
                              fill-opacity="0.5"
                            />
                            <path
                              d="M10.2856 23.2593C10.2856 24.4 11.1428 25.3333 12.1904 25.3333H19.8094C20.8571 25.3333 21.7142 24.4 21.7142 23.2593V10.8148H10.2856V23.2593ZM12.1904 12.8889H19.8094V23.2593H12.1904V12.8889ZM19.3333 7.70369L18.3809 6.66666H13.619L12.6666 7.70369H9.33325V9.77777H22.6666V7.70369H19.3333Z"
                              fill="#b3afb6"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </div>

          <!-- Add option button -->
          <div class="mb-2">
            <button
              class="btn d-flex align-items-center"
              @click="addSingleAnswerOption(index)"
            >
              <span v-if="!addOptionLoading">
                <svg
                  width="12"
                  height="12"
                  class="mr-2"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.8332 6.83334H6.83317V11.8333H5.1665V6.83334H0.166504V5.16667H5.1665V0.166672H6.83317V5.16667H11.8332V6.83334Z"
                    fill="#6D6B6D"
                  />
                </svg>

                <span class="text-muted">Add Option</span></span
              >
              <div
                v-else
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- Answer options End-->

        <!-- Radio Styling -->
        <div class="row my-2 text-setting">
          <div class="col-8 d-flex align-items-center pr-1">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.08333 3.33334V5.83334H6.24999V15.8333H8.74999V5.83334H12.9167V3.33334H2.08333ZM17.9167 7.50001H10.4167V10H12.9167V15.8333H15.4167V10H17.9167V7.50001Z"
                fill="#B3AFB6"
              />
            </svg>

            <select
              class="form-select w-100 form-control-sm input-border-style ml-1"
              aria-label="Default select example"
              v-model="block.style.fontFamily"
            >
              <option
                v-for="font in getFontFamilyList"
                :key="font.value"
                :value="font.value"
              >
                {{ font.value }}
              </option>
            </select>
          </div>
          <div class="col-12 d-flex align-items-center ml-1">
            <div class="d-flex align-items-center">
              <span class="">
                <svg
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                    fill="#B3AFB6"
                  />
                  <path
                    d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </span>
              <input
                type="number"
                min="0"
                max="100"
                v-model="block.style.fontSize"
                class="form-control-sm input-border-style"
              />
            </div>

            <div class="d-flex align-items-center">
              <span
                class="mx-1 font-style-button"
                :class="{
                  'active-svg': block.style.fontWeight === 'bold',
                }"
                @click="
                  block.style.fontWeight =
                    block.style.fontWeight == 'normal' ? 'bold' : 'normal'
                "
              >
                <svg
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.00001 5.99165C8.80834 5.43331 9.37501 4.51665 9.37501 3.66665C9.37501 1.78331 7.91668 0.333313 6.04168 0.333313H0.833344V12H6.70001C8.44168 12 9.79168 10.5833 9.79168 8.84165C9.79168 7.57498 9.07501 6.49165 8.00001 5.99165ZM3.33334 2.41665H5.83334C6.52501 2.41665 7.08334 2.97498 7.08334 3.66665C7.08334 4.35831 6.52501 4.91665 5.83334 4.91665H3.33334V2.41665ZM6.25001 9.91665H3.33334V7.41665H6.25001C6.94168 7.41665 7.50001 7.97498 7.50001 8.66665C7.50001 9.35831 6.94168 9.91665 6.25001 9.91665Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </span>
              <span
                class="mx-1 font-style-button"
                :class="{
                  'active-svg': block.style.fontStyle === 'italic',
                }"
                @click="
                  block.style.fontStyle =
                    block.style.fontStyle == 'normal' ? 'italic' : 'normal'
                "
              >
                <svg
                  width="10"
                  height="12"
                  viewBox="0 0 10 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.33333 0.333313V2.83331H5.175L2.325 9.49998H0V12H6.66667V9.49998H4.825L7.675 2.83331H10V0.333313H3.33333Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </span>
              <span
                class="mx-1 font-style-button"
                :class="{
                  'active-svg': block.style.textDecoration === 'underline',
                }"
                @click="
                  block.style.textDecoration =
                    block.style.textDecoration == 'underline'
                      ? 'unset'
                      : 'underline'
                "
              >
                <svg
                  width="12"
                  height="16"
                  viewBox="0 0 12 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.99999 12.1667C8.75832 12.1667 11 9.925 11 7.16667V0.5H8.91666V7.16667C8.91666 8.775 7.60832 10.0833 5.99999 10.0833C4.39166 10.0833 3.08332 8.775 3.08332 7.16667V0.5H0.99999V7.16667C0.99999 9.925 3.24166 12.1667 5.99999 12.1667ZM0.166656 13.8333V15.5H11.8333V13.8333H0.166656Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>

        <div class="row my-2">
          <div class="col-6 d-flex align-items-center pr-0">
            <input
              type="color"
              v-model="block.style.color"
              style="min-width: 30px; width: 30px; height: 30px"
              class="form-control-color form-control input-border-style"
            />
            <input
              type="text"
              class="ColorTextInput input-border-style"
              v-model="block.style.color"
            />
          </div>
          <div class="col-6 d-flex align-items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              v-model="block.style.textOpacity"
              class="form-control-sm w-50 px-2 ml-2 input-border-style"
            />
          </div>
        </div>

        <!-- Option Position -->
        <div
          class="row mt-2 mb-4"
          v-if="
            (getCurrentSelectedOptionType === 'image' ||
            getOptionAlignment == 'vertical' ||
            getOptionAlignment == 'horizontal') &&
              getCurrentSelectedOptionType !== 'dropdown'
          "
        >
          <div class="button-position col-12">
            <span class="editorStyleTitle mr-2"> Option position: </span>
            <span
              type="button"
              class="mr-2"
              :class="{
                'active-svg': block.style.textAlign === 'left',
              }"
              @click="block.style.textAlign = 'left'"
            >
              <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM3 12H12V15H3V12Z"
                  fill="#323232"
                />
              </svg>
            </span>

            <span
              v-if="getCurrentSelectedOptionType !== 'text'"
              type="button"
              class="mr-2"
              :class="{
                'active-svg': block.style.textAlign === 'center',
              }"
              @click="block.style.textAlign = 'center'"
            >
              <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM6 12H16V15H6V12Z"
                  fill="#6D6B6D"
                />
              </svg>
            </span>

            <span
              type="button"
              class="mr-2"
              :class="{ 'active-svg': block.style.textAlign === 'end' }"
              @click="block.style.textAlign = 'end'"
            >
              <svg
                width="22"
                height="18"
                viewBox="0 0 22 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H20V16ZM10 12H19V15H10V12Z"
                  fill="#6D6B6D"
                />
              </svg>
            </span>
          </div>
        </div>

        <div
          class="mt-2 mb-1"
          v-if="getCurrentSelectedOptionType === 'image'"
        >
          <h4 class="editorStyleTitle mt-4">Options per row:</h4>
          <div class="form-group d-flex align-items-center">
            <input
              v-if="isDesktopView"
              type="range"
              class="form-control-range formControlRange"
              id="OptionDesktopSlider"
              min="1"
              max="4"
              v-model="block.OptionsPerRow"
            />
            <input
              v-else
              type="range"
              class="form-control-range formControlRange"
              id="OptionMobileSlider"
              min="1"
              max="2"
              v-model="block.OptionsPerMobile"
            />

            <span v-if="isDesktopView" class="ml-3">{{
              block.OptionsPerRow
            }}</span>
            <span v-else class="ml-3">{{ block.OptionsPerMobile }}</span>
          </div>
        </div>
        <div
          class="mt-1 mb-1"
          v-if="getCurrentSelectedOptionType === 'image'"
        >
          <h4 class="editorStyleTitle">Space between row:</h4>
          <div class="form-group d-flex align-items-center">
            <input
              type="number"
              min="0"
              v-model="block.style.spaceBetweenRow"
              style="width: 50px; height: 30px"
              class="form-control-sm input-border-style"
            />
            <span class="ml-2">px</span>
          </div>
        </div>
        <div
          class="mt-2 mb-1"
          v-if="getCurrentSelectedOptionType === 'image'"
        >
          <h4 class="editorStyleTitle mt-4">Selected Image Icon:</h4>
          <div class="form-group row">
            <div class="col-6 d-flex align-items-center pr-0">
              <input
                type="color"
                v-model="block.style.selectedImageIconColor"
                style="min-width: 30px; width: 30px; height: 30px"
                class="form-control-color form-control input-border-style"
              />
              <input
                type="text"
               class="ColorTextInput input-border-style"
                v-model="block.style.selectedImageIconColor"
              />
            </div>
            <div class="col-5 d-flex align-items-center">
              <span class="">
                <svg
                  width="19"
                  height="13"
                  viewBox="0 0 19 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6666 3.825H18.3333L15.8333 0.5L13.3333 3.825H14.9999V9.675H13.3333L15.8333 13L18.3333 9.675H16.6666V3.825Z"
                    fill="#B3AFB6"
                  />
                  <path
                    d="M0.833282 3V0.5H11.6666V3H7.49995V13H4.99995V3H0.833282Z"
                    fill="#B3AFB6"
                  />
                </svg>
              </span>

              <input
                type="number"
                v-model="block.style.selectedImageIconColorSize"
                class="form-control-sm w-50 px-2 ml-2 input-border-style"
              />
            </div>
          </div>
        </div>
        <!-- Option Arrangement -->
        <div
          class="form-group d-flex mb-2 align-items-center row"
          v-if="
           getCurrentSelectedOptionType === 'text' ||
           getCurrentSelectedOptionType == 'tab'
          "
        >
        <div class="d-flex align-items-center col-12 mb-2" v-if="!isDesktopView">
              <div
                class="show-logo custom-control custom-switch cursor-pointer"
              >
                <input
                  type="checkbox"
                  class="custom-control-input"
                  :id="`useSeperateOptionAlignment`"
                  v-model="block.useSeperateOptionAlignment"
                />

                <label
                  class="custom-control-label"
                  :for="`useSeperateOptionAlignment`"
                ></label>
              </div>
              <span
                class="sidebarElementTitle ml-2"
                style="font-size: 14px; font-weight: 500"
                >Use Seperate Mobile Alignment</span
              >
            </div>

          <div class="col-5 d-flex mb-2 align-items-center">
            <span class="editorStyleTitle">Arrangement</span>
          </div>

          <div class="col-7 d-flex align-items-center p-0">
            <select
            v-if="block.useSeperateOptionAlignment && !isDesktopView"
              class="form-select w-75 form-control-sm mb-2 reset-option"
              aria-label="Default select example"
              v-model="block.mobileTextOptionAlignment"
            >
              <option value="vertical">Vertical</option>
              <option value="horizontal" selected>Horizontal</option>
            </select>
            <select
            v-else
              class="form-select w-75 form-control-sm mb-2 reset-option"
              aria-label="Default select example"
              v-model="block.textOptionAlignment"
            >
              <option value="vertical">Vertical</option>
              <option value="horizontal" selected>Horizontal</option>
            </select>
          </div>

          <!-- Horizontal Tab total options per row -->

          <div
            class="mb-1 px-3 w-100"
            v-if="getOptionAlignment == 'horizontal'"
          >
            <h4 class="editorStyleTitle mt-4">
              {{
                isDesktopView ? "Options per row:" : "Options per row (mobile):"
              }}
            </h4>
            <div
              class="form-group d-flex align-items-center"
              v-if="isDesktopView"
            >
              <input
                type="range"
                class="form-control-range formControlRange"
                min="2"
                max="4"
                :key="getOptionAlignment + 'desktop'"
                v-model="block.optionSetting.horizontalOptionsPerRow"
              />

              <span class="ml-3">{{
                block.optionSetting.horizontalOptionsPerRow
              }}</span>
            </div>
            <div class="form-group d-flex align-items-center" v-else>
              <input
                type="range"
                class="form-control-range formControlRange"
                min="2"
                max="4"
                :key="getOptionAlignment+ 'mobile'"
                v-model="block.optionSetting.horizontalOptionsPerRowMobile"
              />

              <span class="ml-3">{{
                block.optionSetting.horizontalOptionsPerRowMobile
              }}</span>
            </div>
          </div>

          <!-- Horizontal Tab total options per row end-->

          <div class="col-12">
            <div class="row">
              <div class="col-12 d-flex align-items-center">
                <span class="editorStyleTitle">Input select color</span>
              </div>
              <div class="col-8 d-flex align-items-center pr-0">
                <input
                  type="color"
                  v-model="block.style.selectedCheckboxColor"
                  style="min-width: 30px; width: 30px; height: 30px"
                  class="form-control-color form-control input-border-style"
                />
                <input
                  type="text"
                   class="ColorTextInput input-border-style"
                  v-model="block.style.selectedCheckboxColor"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          class="form-group d-flex mb-2 align-items-center row"
          v-if="getCurrentSelectedOptionType == 'tab'"
        >
          <div class="col-12 d-flex align-items-center">
            <span class="editorStyleTitle">Text Alignment:</span>
          </div>
          <div class="col-5 d-flex align-items-center pr-0">
            <span
              class="mx-2 font-style-button"
              type="button"
              :class="{
                'active-svg': block.style.tabBtnTextAlign === 'left',
              }"
              @click="block.style.tabBtnTextAlign = 'left'"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.22223 9.2222H0.333344V10.7037H9.22223V9.2222ZM9.22223 3.29628H0.333344V4.77776H9.22223V3.29628ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span
              class="mx-2 font-style-button"
              type="button"
              :class="{
                'active-svg': block.style.tabBtnTextAlign === 'center',
              }"
              @click="block.style.tabBtnTextAlign = 'center'"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3.29631 9.2222V10.7037H10.7037V9.2222H3.29631ZM0.333344 13.6666H13.6667V12.1852H0.333344V13.6666ZM0.333344 7.74072H13.6667V6.25924H0.333344V7.74072ZM3.29631 3.29628V4.77776H10.7037V3.29628H3.29631ZM0.333344 0.333313V1.81479H13.6667V0.333313H0.333344Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
            <span
              class="mx-2 font-style-button"
              type="button"
              :class="{
                'active-svg': block.style.tabBtnTextAlign === 'right',
              }"
              @click="block.style.tabBtnTextAlign = 'right'"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.166656 11.8334H11.8333V10.5371H0.166656V11.8334ZM4.05555 9.24076H11.8333V7.94447H4.05555V9.24076ZM0.166656 6.64817H11.8333V5.35187H0.166656V6.64817ZM4.05555 4.05558H11.8333V2.75928H4.05555V4.05558ZM0.166656 0.166687V1.46298H11.8333V0.166687H0.166656Z"
                  fill="#B3AFB6"
                />
              </svg>
            </span>
          </div>
          <div class="col-12 d-flex flex-column pt-2 pb-1">
            <div>
              <span class="editorStyleTitle">{{
                isDesktopView ? "Min Width (Desktop)" : "Min Width (Mobile)"
              }}</span>
              <input
                v-if="isDesktopView"
                type="number"
                v-model="block.style.defaultTab.minTabWidthDesktop"
                class="form-control-sm w-50 px-2 ml-2 input-border-style"
                style="max-width: 55px"
              />
              <input
                v-else
                type="number"
                v-model="block.style.defaultTab.minTabWidthMobile"
                class="form-control-sm w-50 px-2 ml-2 input-border-style"
                style="max-width: 55px"
              />
            </div>
            <div class="mt-2">
              <span class="editorStyleTitle">{{
                isDesktopView ? "Max Width (Desktop)" : "Max Width (Mobile)"
              }}</span>
              <input
                v-if="isDesktopView"
                type="number"
                v-model="block.style.defaultTab.maxTabWidthDesktop"
                class="form-control-sm w-50 px-2 ml-2 input-border-style"
                style="max-width: 55px"
              />
              <input
                v-else
                type="number"
                v-model="block.style.defaultTab.maxTabWidthMobile"
                class="form-control-sm w-50 px-2 ml-2 input-border-style"
                style="max-width: 55px"
              />
            </div>
          </div>
          <div class="col-12 d-flex align-items-center pt-2 pb-1">
            <span class="editorStyleTitle">Default Tab :</span>
          </div>
          <div class="col-6 col-xl-5 d-flex align-items-center pr-0">
            <input
              type="color"
              v-model="block.style.defaultTab.backgroundColor"
              style="min-width: 30px; width: 30px; height: 30px"
              class="form-control-color form-control input-border-style"
            />
            <!-- <span> {{ DefaultBgColorOutput(block.style.defaultTab.backgroundColor)  }}</span> -->
            <input
              type="text"
               class="ColorTextInput input-border-style"
              v-model="block.style.defaultTab.backgroundColor"
            />
          </div>
          <div class="col-6 col-xl-4 d-flex align-items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              min="0"
              max="100"
              v-model="block.style.defaultTab.bgOpacity"
              class="form-control-sm w-50 px-2 ml-2 input-border-style"
            />
          </div>
          <div class="col-6 col-xl-3 d-flex align-items-center pr-0">
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 15.8333H17.5V17.5H15.8333V15.8333ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM2.5 10.8333H4.16667V9.16667H2.5V10.8333ZM2.5 14.1667H4.16667V12.5H2.5V14.1667ZM2.5 7.5H4.16667V5.83333H2.5V7.5ZM2.5 4.16667H4.16667V2.5H2.5V4.16667ZM5.83333 4.16667H7.5V2.5H5.83333V4.16667ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM5.83333 17.5H7.5V15.8333H5.83333V17.5ZM2.5 17.5H4.16667V15.8333H2.5V17.5ZM17.5 6.66667C17.5 4.36667 15.6333 2.5 13.3333 2.5H9.16667V4.16667H13.3333C14.7083 4.16667 15.8333 5.29167 15.8333 6.66667V10.8333H17.5V6.66667Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              v-model="block.style.defaultTab.borderRadius"
              class="form-control-sm w-50 px-2 ml-2 input-border-style"
              style="max-width: 55px"
            />
          </div>
        </div>
        <div
          class="form-group d-flex mb-2 align-items-center row"
          v-if="getCurrentSelectedOptionType == 'tab'"
        >
          <div class="col-12 d-flex align-items-center">
            <span class="editorStyleTitle">Selected Tab :</span>
          </div>
          <div class="col-6 col-xl-5 d-flex align-items-center pr-0">
            <input
              type="color"
              v-model="block.style.selectedTab.backgroundColor"
              style="min-width: 30px; width: 30px; height: 30px"
              class="form-control-color form-control input-border-style"
            />
            <!-- <span> {{  SelectedBgColorOutput( block.style.selectedTab.backgroundColor )}}</span> -->
            <input
              type="text"
             class="ColorTextInput input-border-style"
              v-model="block.style.selectedTab.backgroundColor"
            />
          </div>
          <div class="col-6 col-xl-4 d-flex align-items-center">
            <svg
              width="20"
              height="20"
              viewBox="0 0 14 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.7167 5.66665L7.00001 0.958313L2.28334 5.66665C0.983344 6.96665 0.333344 8.69998 0.333344 10.3666C0.333344 12.0333 0.983344 13.7916 2.28334 15.0916C3.58334 16.3916 5.29168 17.05 7.00001 17.05C8.70834 17.05 10.4167 16.3916 11.7167 15.0916C13.0167 13.7916 13.6667 12.0333 13.6667 10.3666C13.6667 8.69998 13.0167 6.96665 11.7167 5.66665ZM2.00001 10.6666C2.00834 8.99998 2.51668 7.94165 3.46668 6.99998L7.00001 3.39165L10.5333 7.04165C11.4833 7.97498 11.9917 8.99998 12 10.6666H2.00001Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              min="0"
              max="100"
              v-model="block.style.selectedTab.bgOpacity"
              class="form-control-sm w-50 px-2 ml-2 input-border-style"
            />
          </div>
          <div class="col-6 col-xl-3 d-flex align-items-center pr-0">
            <svg
              width="18"
              height="18"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.8333 15.8333H17.5V17.5H15.8333V15.8333ZM15.8333 14.1667H17.5V12.5H15.8333V14.1667ZM2.5 10.8333H4.16667V9.16667H2.5V10.8333ZM2.5 14.1667H4.16667V12.5H2.5V14.1667ZM2.5 7.5H4.16667V5.83333H2.5V7.5ZM2.5 4.16667H4.16667V2.5H2.5V4.16667ZM5.83333 4.16667H7.5V2.5H5.83333V4.16667ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM9.16667 17.5H10.8333V15.8333H9.16667V17.5ZM12.5 17.5H14.1667V15.8333H12.5V17.5ZM5.83333 17.5H7.5V15.8333H5.83333V17.5ZM2.5 17.5H4.16667V15.8333H2.5V17.5ZM17.5 6.66667C17.5 4.36667 15.6333 2.5 13.3333 2.5H9.16667V4.16667H13.3333C14.7083 4.16667 15.8333 5.29167 15.8333 6.66667V10.8333H17.5V6.66667Z"
                fill="#B3AFB6"
              />
            </svg>

            <input
              type="number"
              v-model="block.style.selectedTab.borderRadius"
              class="form-control-sm w-50 px-2 ml-2 input-border-style"
              style="max-width: 55px"
            />
          </div>
        </div>
        <div
          class="form-group d-flex mb-2 align-items-center row"
          v-if="getCurrentSelectedOptionType == 'tab'"
        >
          <div class="col-12 d-flex align-items-center">
            <span class="editorStyleTitle pb-1 pt-2">Selected Tab Text :</span>
          </div>
          <div class="col-12 d-flex align-items-center">
            <input
              type="color"
              v-model="block.style.selectedTab.textColor"
              style="min-width: 30px; width: 30px; height: 30px"
              class="form-control-color form-control input-border-style"
            />
            <input
              type="text"
               class="ColorTextInput input-border-style"
              v-model="block.style.selectedTab.textColor"
            />
          </div>
        </div>

        <!-- Margin -->

        <div class="row m-0" v-if="isDesktopView">
          <h4 class="editorStyleTitle mt-4">Margins:</h4>
          <div class="row align-items-center">
            <div class="col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.marginTop"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="14"
                    height="16"
                    viewBox="0 0 14 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.marginBottom"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="form-group col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.marginLeft"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
            <div class="form-group col-6 d-flex">
              <div class="row align-items-center">
                <div class="col-4">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </div>
                <div class="col-8">
                  <input
                    type="number"
                    v-model="block.style.marginRight"
                    class="form-control-sm w-75 px-2 input-border-style"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-2" v-else>
          <h4 class="editorStyleTitle mt-4">Mobile Option margin:</h4>
          <div class="row m-0">
            <div class="row align-items-center">
              <div class="col-6 d-flex">
                <div class="row align-items-center">
                  <div class="col-4">
                    <svg
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.3334 7.16667H7.83337V15.5H6.16671V7.16667H3.66671L7.00004 3.83333L10.3334 7.16667ZM0.333374 2.16667V0.5H13.6667V2.16667H0.333374Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </div>
                  <div class="col-8">
                    <input
                      type="number"
                      min="0"
                      placeholder="Top"
                      v-model="block.style.mobileMarginTop"
                      class="form-control-sm w-75 px-2 input-border-style"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 d-flex">
                <div class="row align-items-center">
                  <div class="col-4">
                    <svg
                      width="14"
                      height="16"
                      viewBox="0 0 14 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.3334 8.83333H7.83337V0.5H6.16671V8.83333H3.66671L7.00004 12.1667L10.3334 8.83333ZM0.333374 13.8333V15.5H13.6667V13.8333H0.333374Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </div>
                  <div class="col-8">
                    <input
                      type="number"
                      min="0"
                      placeholder="Bottom"
                      v-model="block.style.mobileMarginBottom"
                      class="form-control-sm w-75 px-2 input-border-style"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-3">
              <div class="form-group col-6 d-flex">
                <div class="row align-items-center">
                  <div class="col-4">
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.16667 3.66663V6.16663L15.5 6.16663V7.83329L7.16667 7.83329V10.3333L3.83333 6.99996L7.16667 3.66663ZM2.16667 13.6666L0.5 13.6666V0.333294L2.16667 0.333294V13.6666Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </div>
                  <div class="col-8">
                    <input
                      type="number"
                      v-model="block.style.mobileMarginLeft"
                      min="0"
                      placeholder="Left"
                      class="form-control-sm w-75 px-2 input-border-style"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group col-6 d-flex">
                <div class="row align-items-center">
                  <div class="col-4">
                    <svg
                      width="16"
                      height="14"
                      viewBox="0 0 16 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.83333 3.66663V6.16663L0.5 6.16663L0.5 7.83329L8.83333 7.83329V10.3333L12.1667 6.99996L8.83333 3.66663ZM13.8333 13.6666L15.5 13.6666V0.333294L13.8333 0.333294V13.6666Z"
                        fill="#B3AFB6"
                      />
                    </svg>
                  </div>
                  <div class="col-8">
                    <input
                      type="number"
                      v-model="block.style.mobileMarginRight"
                      min="0"
                      placeholder="Right"
                      class="form-control-sm w-75 px-2 input-border-style"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ImageFileModal
      :selectedImage="imageSrc"
      @selectedImageCallbackModal="selectImageDone($event)"
      ref="ImageFileCompRefSingleAns"
    />
  </div>
</template>
<script>
import axios from "axios";
import draggable from "vuedraggable";
import { mapGetters } from "vuex";
import fontFamilyList from "../../../staticPages/fontFamilyList";
// import ImageFileModal from "../../component/ImageFileModal.vue";
import ImageFileModal from "../../../component/ImageFileModal.vue";
export default {
  components: {
    draggable,
    ImageFileModal,
  },
  props: {
    block: Object,
    index: Number,
    isDesktopView: Boolean,
    QuestionPageType: String,
  },
  data() {
    return {
      addOptionLoading: false,
      deleteOptionLoading: false,
      selectedImageInputIndex: null,
      imageSrc: null,
    };

    //   this.contentLocal = this.content;
  },
  methods: {
    async addSingleAnswerOption() {
      const obj = {
        id: Date.now(),
        value: "Answer Option ",
        image: null,
        detailValue: "",
      };
      const option = await this.AddOptionObj(obj);
      this.block.options.push(option);
    },
    async AddOptionObj(obj) {
      this.addOptionLoading = true;
      const quesID = this.getSelectedPage.id;
      try {
        let data = {
          quiz_id: parseInt(localStorage.getItem("QuizID")),
          quizId: parseInt(localStorage.getItem("QuizID")),
          questionId: quesID,
          option: JSON.stringify(obj),
        };
        const response = await axios.post(`/createQuestionOption`, data);
        if (response.status == 200 && response.data.status == "success") {
          return response.data.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.addOptionLoading = false;
      }
    },
    async deleteSingleAnswerOption(subIndex, optionId) {
      this.deleteOptionLoading = true;
      const quesID = this.getSelectedPage.id;
      try {
        let data = {
          questionId: quesID,
          optionId: optionId,
          quizId: parseInt(localStorage.getItem("QuizID")),
        };
        const response = await axios.post(`/removeQuestionOption`, data);
        if (response.status == 200) {
          this.$toasted.show("Option deleted", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
          // this.pageDesign.blocksArray[index].options.splice(subIndex, 1);
          this.block.options.splice(subIndex, 1);
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.deleteOptionLoading = false;
      }
    },
    openModal(index, img) {
      this.selectedImageInputIndex = index;

      if (img) {
        if (img.startsWith("/")) {
          this.imageSrc = img.substring(1);
        } else {
          this.imageSrc = img;
        }

        this.imageSrc = null;
      }
      this.$refs.ImageFileCompRefSingleAns.openModalFunc();
    },
    selectImageDone(imageSrc) {
      let newImage;
      if (imageSrc.startsWith("http")) {
        newImage = imageSrc;
      } else {
        newImage = "/" + imageSrc;
      }

      this.block.options[this.selectedImageInputIndex].image = newImage;
      this.imageSrc = null;
      this.selectedImageInputIndex = null;
    },
    ImageSrc(img) {
      // return img.startsWith('/http') ? `/${img}` : img
      // return img.startsWith("/http") ? img.substring(1) : img;
      let newImage;
      if (img.startsWith("/http")) {
        newImage = img.substring(1);
      } else {
        newImage = img;
      }
      if (
        newImage.indexOf("http://") === 0 ||
        newImage.indexOf("https://") === 0
      ) {
        return newImage;
      } else {
        const baseURL = process.env.VUE_APP_API_BASE_URL;
        return baseURL + newImage.substring(1);
      }
    },
    setOptionType(type) {
      if (this.isDesktopView) {
        this.block.optionSetting.optionType = type;
        if (!this.block.optionSetting.mobileOptionsView) {
          this.block.optionSetting.mobileOptionsView = type;
        }
      } else {
        this.block.optionSetting.mobileOptionsView = type;
      }
    },
  },
  computed: {
    ...mapGetters(["getSelectedPage"]),
    getFontFamilyList() {
      return fontFamilyList;
    },
    getOptionAlignment(){
      if (this.block.useSeperateOptionAlignment && !this.isDesktopView) {
     return  this.block.mobileTextOptionAlignment ;
    } else {
      return this.block.textOptionAlignment;
    }
    },
    getCurrentSelectedOptionType() {
      if (this.isDesktopView) {
        return this.block.optionSetting.optionType;
      } else {
        return (
          this.block.optionSetting.mobileOptionsView ??
          this.block.optionSetting.optionType
        );
      }
    },
    textBlockFont(){
      return this.block.style.fontFamily
    },
  },
  watch:{
    textBlockFont(newVal,oldVal){
      if(oldVal && newVal){
        this.$store.dispatch('updateFontFamily', { oldFontFamily:oldVal, newFontFamily:newVal });
        this.$store.dispatch('loadFontsFromTracker');
      }
    }
  }
};
</script>
<style scoped>
.text-setting select {
  font-size: 13px;
  padding: 0;
}
.button-group {
  color: #535353;
  outline: #c4c4c4 solid 1px;
}
.btn-group {
  border: 1px solid #e0dee2;
  border-radius: 2px;
}
.btn-group .btn {
  border: none;
}
.button-selected {
  background: #000000 !important;
  color: #ffffff !important;
}

.text-img-btn-selected {
  background: #ffa201 !important;
  color: #ffffff !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

.PropertyDropdown :deep(.dropdown-toggle) {
  background: none;
  color: #000;
}
.PropertyDropdown :deep(.dropdown-item:active) {
  color: #212529;
  background-color: transparent;
}

.OptionsContainer {
  width: 100%;
  padding: 10px;
  max-height: 500px;
  overflow: hidden scroll;
  scroll-behavior: smooth;
}
.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}
.custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff !important;
}
</style>
